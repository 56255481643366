import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import bgHero from '../assets/images/bg-hero.png'
import Monitor from '../assets/icons/data.svg'
import CardLine from '../components/CardLine'
import homeData from '../assets/data/home.json'
import Call from '../assets/icons/call.svg'
import Link from 'gatsby-link'

const IndexPage = () => {
  const dataCardFramework = homeData.cardFramework
  return (
    <Layout>
      <SEO title='Home' />
      {/* Begin Hero */}
      <section className='hero'>
        <div
          className='bg-cover bg-center h-56 md:h-64 xl:h-96'
          style={{backgroundImage: `url(${bgHero})`}}
        >
          <div className='container mx-auto bg-gray content-hero flex content-center text-center'>
            <div className='self-center p-2 w-64 md:w-96 lg:w-132 xl:w-288'>
              <h1 className='text-xl md:text-2xl xl:text-4xl p-2 mb-4 text-left xs:mt-4'>
                Cintanegra
              </h1>
              <p className='text-sm md:text-base p-2 xl:text-lg mb-4 text-left'>
                Desarrolla con los mejores, crea el próximo producto tendencia
                del mercado.
              </p>
              <button className='justify-center bg-orange-500 hover:bg-orange-400 text-white font-bold py-2 px-4 border-b-4 border-orange-700 hover:border-orange-500 rounded'>
                <Link to='/'> Contactanos</Link>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* Begin Our History */}
      <section className='bg-white border-b py-8'>
        <div className='container max-w-5xl mx-auto m-8'>
          <div className='w-full mb-4'>
            <div className='h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t'></div>
          </div>
          <div className='flex flex-wrap'>
            <div className='w-5/6 xs:w-full sm:w-1/2 p-6'>
              <h3 className='text-xl text-gray-800 font-bold leading-none mb-3'>
                ¿QUÉ ES CINTANEGRA?
              </h3>
              <p className='text-gray-600 mb-8 text-justify text-base'>
                Somos una empresa con una vasta trayectoria, que ofrece las
                mejores soluciones que van de la mano con la tecnología,
                llevamos trabajando varios años, y nuestros resultados han sido
                extraordinarios. Contamos con profesionales perfectamente
                calificados que garantizan la calidad y eficiencia de nuestro
                trabajo.
              </p>
            </div>
            <div className='w-full xs:w-full sm:w-1/2 p-6 content-center'>
              <Monitor className='w-56 h-48 is-center' />
            </div>
          </div>
        </div>
      </section>
      {/* Begin Section Dev */}
      <section className='bg-white py-8'>
        <div className='container mx-auto flex flex-wrap pt-4 pb-12'>
          <h2 className='w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800'>
            Frameworks
          </h2>
          <h4 className='w-full my-2 text-lg font-bold leading-tight text-center text-gray-600 sm:text-sm xs:text-sm'>
            Estos son algunos de los frameworks tops con los que trabajamos
          </h4>
          {dataCardFramework.map(item => {
            return (
              <div
                className='w-full xs:w-1/3 md:w-1/3 p-6 flex flex-col flex-grow flex-shrink'
                key={item.id}
              >
                <CardLine item={item} />
              </div>
            )
          })}
        </div>
      </section>
      {/* Call to action */}
      <section className='mx-auto w-full gradient'>
        <Call></Call>
        <section className='container mx-auto text-center py-6'>
          <h2 className='w-full my-2 text-3xl font-bold leading-tight text-center text-white'>
            Programa con los mejores.
          </h2>
          <div className='w-full mb-4'>
            <div className='h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t'></div>
          </div>

          <h4 className='my-4 text-xl leading-tight'>
            ¿Quieres crear tu próxima forma de captar más clientes?
          </h4>

          <button className='mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg'>
            <Link to='/'> Contactanos</Link>
          </button>
        </section>
      </section>
    </Layout>
  )
}

export default IndexPage
