import React from 'react'
import AngularSVG from '../../assets/icons/angular.svg'
import ReSVG from '../../assets/icons/react-icon.svg'
import VueSVG from '../../assets/icons/vue.svg'
import LaravelSVG from '../../assets/icons/laravel.svg'
import DjangoSVG from '../../assets/icons/django.svg'
import InfinytiSVG from '../../assets/icons/infinyti.svg'

const CardLine = props => {
  const item = props.item
  function getImage(value) {
    switch (value) {
      case 'angular-icon':
        return <AngularSVG />
      case 'r-icon':
        return <ReSVG />
      case 'vue-icon':
        return <VueSVG />
      case 'laravel-icon':
        return <LaravelSVG />
      case 'django-icon':
        return <DjangoSVG />
      case 'infinyti-icon':
        return (
          <InfinytiSVG className='xs:mt-4 xs:mb-2 sm:mt-12 sm:mb-12 md:mt-4 md:mb-4 lg:mt-8 lg:mb-8 xl:mt-12 xl:mb-12  infinyti-logo' />
        )
      default:
        return null
    }
  }
  return (
    <div className='relative rounded-lg block md:flex items-center bg-gray-100 shadow-xl'>
      <div className='relative w-full md:w-2/5  overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg justify-center content-center is-center'>
        <div className='p-4 self-center'>{getImage(item.img)}</div>
      </div>
      <div className='hidden sm:flex xl:hidden lg:hidden bg-gray-100 p-4 flex text-center'>
        <h4 className='text-gray-500 is-center'>{item.name}</h4>
      </div>
      <div className='w-full md:w-3/5 h-full flex items-center bg-gray-100 rounded-lg hidden lg:flex xl:flex'>
        <div className='p-12 md:pr-2 md:pl-2 md:py-2'>
          <p className='text-sm p-2 text-gray-600 text-justify'>
            {item.description}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CardLine
